@import "layout";

.ant-layout {
  min-height: 100vh;
}

.card-flex {
  .ant-card-head-wrapper {
    width: 100%;
  }
}

.centered-image {
  max-width: 300px;
  height: auto;
  margin: 0 auto;
  > img {
    width: 100%;
    height: auto;
  }
}
.buttons {
  min-width: 124px;
  justify-content: space-between;
}

.ant-table-wrapper.no-highlight {
  .ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
  .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
  .ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td,
  .ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
    background: unset; //Change the existing color to `unset`
  }
}

