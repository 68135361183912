$black: #000;
$white: #eee;
$orange: #c15342;

@import "scss/style";

.LoginView {
  @apply bg-gray-900 #{!important};
  background-image: url('./assets/images/bg-creadomotics.png');
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 800px;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;

  .card {
    width: 350px;
    display: flex;
    background-color: $white;
    flex-direction: column;
    border-radius: 10px;
    padding: 30px 15px;
    border: 4px solid #287abe;
    align-items: center;
    box-shadow: 0px 0px 15px 1px rgb(40 122 190);
    position: relative;
    margin-top:140px;

    > * {
      margin-top: 10px;
      margin-bottom: 10px;
      color: #287abe;
    }

    .error {
      @apply text-blue-500;
      width: 75%;
      text-align: center;
    }

    h1 {
      padding: 0;
    }

    h2 {
      padding: 0;
    }

    img {
      position: absolute;
      top: -100px;
      left: 0;
      width: 100%;
    }

    .login-form {
      width: 80%;
      border-bottom: 2px solid $orange;

      input {
        width: 100%;
        border-radius: 5px;
        outline: none;
        border: none;
      }
    }

    .login-btn {
      @apply bg-blue-500;

      margin-top: 40px;
      //margin-bottom:40px;
      border-radius: 80px;
      width: 60%;
      color: $white;
    }

  }

}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

header .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

header .trigger:hover {
  color: #1890ff;
}

aside .logo {
  height: 32px;
  margin: 16px;

  > svg {
    @apply fill-current #{!important};
    @apply text-blue-500 #{!important};
    height: 32px;
  }
}

.ant-layout-sider {
  @apply bg-gray-900 #{!important};
}
.ant-menu.ant-menu-dark {
  @apply bg-gray-900 #{!important};
}
.ant-menu-dark .ant-menu-item {
  @apply text-blue-500;
}
.ant-menu-dark .ant-menu-item:hover {
  @apply text-blue-500;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  @apply bg-blue-500;
}
.ant-menu-item-selected a, .ant-menu-item-selected a:hover {
  color: $black;
}
.ant-layout {
  @apply bg-blue-500;
}
.ant-layout-header {
  @apply bg-gray-900 #{!important};
  @apply border-blue-500;

  border-left-width: 4px;
  border-left-style: solid;
  border-bottom-left-radius: 8px;
}
.ant-layout-header svg {
  @apply text-blue-500;
}
.ant-layout-content {
  background-color: #eee;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin: 24px 16px 0 16px;
  padding: 24px;
}

h1.ant-typography {
  @apply text-blue-500;
  padding-bottom: 0;
  margin-bottom: 0;
}
.CSVImporter_FileSelector {
  border: 4px dashed;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  width: 100%;
  height: 200px;
}
